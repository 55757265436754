import axios from 'axios';
import {authService} from "./auth.service";


class UserApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';

        axios.interceptors.request.use(async (config) => {

            const token = await authService.getToken();
            config.headers.Authorization =  `Bearer ${token.idToken.rawIdToken}`;

            return config;
        }, (error) => {

            return Promise.reject(error);
        });
    }

    getPersonSummary() {

        return axios.get(`${this.baseUrl}api/consumers/me`)
            .then(function (res) { return res; });
    }
}

export const userApi = new UserApi();
