import axios from 'axios';


class RegistrationApi {

    constructor() {

        this.baseUrl = process.env.REACT_APP_DH_API_BASE_URL || '';
    }

    register(form) {

        return axios.post(`${this.baseUrl}/api/users/_register`, form)
            .then(function (res) { return res; });
    }
}

export const registrationApi = new RegistrationApi();