import React, { Component } from 'react';
import {BrowserRouter, Link, Switch} from "react-router-dom";
import { authService } from '../../utils/auth.service'
import {PrivateRoute} from "../../utils/protected.route";
import Conversation from "../Conversation";
import WelcomeBack from "./welcome";
import AppNav from "../Shared/AppNav";
import UserContext from "../../utils/user.context";
import {userApi} from "../../utils/user.service";

class Dashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: authService.getUser(),
        };
    }

    render() {

        const { user } = this.state;

        return (
            <UserContext.Provider value={{
                user: user,
            }}>
                <AppNav></AppNav>
                <BrowserRouter>
                    <Switch>
                        <PrivateRoute exact path="/" user={ user } component={ WelcomeBack } />
                        <PrivateRoute path="/conversation" component={ Conversation } />
                    </Switch>
                </BrowserRouter>
            </UserContext.Provider>
        );
    }
}

export default Dashboard;
