import React from 'react';
import { Route, Redirect } from 'react-router-dom'
import { authService } from './auth.service'

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route { ...rest } render={ (props) => {

        const user = authService.getUser();

        return (
            user
            ? <Component user={ user } { ...props } />
            : <Redirect push to='/login' />
    )}
    } />
);