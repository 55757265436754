import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Button } from '@material-ui/core';

import { authService } from '../../utils/auth.service'

import logo from '../../assets/img/logo.png';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {
      user: null,
      loginFailed: null,
      loading: true,
    };


    this.loadingChecker = setInterval(() => {

      if (!authService.loginInProgress()) {

        this.getUser();
      }
    }, 100);
  }

  login = () => {
    this.setState({
      loginFailed: false
    });
    authService.login();
  };

  getUser = () => {

    const user = authService.getUser();

    console.log("Tried user", user);

    if (user) {
      this.setState({
        loading: false,
        user: user
      });
    } else {
      this.setState({
        loading: false,
      });
    }

    clearInterval(this.loadingChecker);
  };

  render() {

    const {
      loading,
      user
    } = this.state;

    if (loading) {

      return (<Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      ></Grid>);
    }

    if(user) {

      console.log("Yay a user lets get you to the dashboard.");
      return (<Redirect push to="/"/>);
    }

    return (
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="center"
      >

        {this.state.user ?
          <div className="containerCentered">
            <Grid item xs={12}>
              <Typography variant="h5" >Welcome Back, {this.state.user.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Link to="/conversation">
                <Button>Start Conversation <ArrowForward /></Button>
              </Link>
              {/* <br />
              <Link to="/telehealth">
                <Button>Join Telehealth Session <ArrowForward /></Button>
              </Link> */}
            </Grid>
          </div>
          :
          <>
            <Grid item xs={12}>
              <Typography variant="h1" gutterBottom className="h1Title" >Decoded Health</Typography>
            </Grid>
            <Grid item xs={12}>
              <img src={logo} className="logo" alt="Decoded Health Logo" />
            </Grid>
            <Grid item xs={12}>
              <Grid container
                direction="column"
                justify="space-between"
                alignItems="center"
                spacing={16}
              >
                <Grid item xs={12}>
                  <Button onClick={this.login}>Login to Decoded Health <ArrowForward /></Button>
                </Grid>
              </Grid>
            </Grid>
          </>
        }
      </Grid>
    );
  }
}

export default Login;