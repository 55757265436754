import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { PrivateRoute } from "../../utils/protected.route";
import Login from "../Login";
import Register from "../Register";
import Dashboard from "../Dashboard";
import { authService } from "../../utils/auth.service";
import Grid from "@material-ui/core/Grid";
import { IntlProvider } from "react-intl";


class App extends Component {

  constructor(props) {
    super(props);
    this.theme = this.getTheme();
    this.state = {
      loading: false,
    };
  }

  getTheme = () => {
    return createMuiTheme({
      palette: {
        type: "light"
      },
      overrides: {
        MuiAppBar: {
          colorPrimary: {
            backgroundColor: 'transparent',
            boxShadow: 'none'
          }
        }
      },
      typography: {
        fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(', '),
        useNextVariants: true,
        button: {
          fontFamily: ['Karla', 'Arial', 'sans-serif'].join(', '),
          fontSize: '1.1rem'
        }
      },
    })
  };

  render() {

    console.log("Test");

    const { loading } = this.state;

    if (loading) {

      return (<Grid
          container
          direction="column"
          justify="space-between"
          alignItems="center"
      ></Grid>);
    }


    return (
      <MuiThemeProvider theme={this.theme}>
        <IntlProvider>
          <div className="app">
            <BrowserRouter>
              <Switch>
                <Route path="/login" exact component={ Login } />
                <Route path="/register" exact component={ Register } />
                <PrivateRoute path="/" component={ Dashboard } />
              </Switch>
            </BrowserRouter>
          </div>
        </IntlProvider>
      </MuiThemeProvider>
    );
  }
}

export default App;
