import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { DirectLine } from 'botframework-directlinejs';
import ReactWebChat from 'botframework-webchat';
import { Grid } from '@material-ui/core';
import botDisplayImage from '../../assets/img/dh-display-image.png';
import './Conversation.css';

import { authService } from '../../utils/auth.service'
import {userApi} from "../../utils/user.service";

class Conversation extends Component {

  async LoadWebChat(consumer) {
    // const res = await fetch(process.env.REACT_APP_DH_Patient_DirectLine_Bot, { method: 'POST' });
    const token = process.env.REACT_APP_DH_BOT_SECRET;
    const directLine = process.env.NODE_ENV === "production" ? 
      new DirectLine({ secret: token }) : 
      new DirectLine({ secret: token, webSocket: false, domain: process.env.REACT_APP_BOT_DOMAIN });
    
    const user = authService.getUser();
    const chatUser = {
      id: consumer.id,
      name: user.name,
      role: "user",
      userInitials: this.GetUserInitials(user)
    }

    const styleOptions = this.WebChatStyle(chatUser);
    
    ReactDOM.render(
      <ReactWebChat directLine={ directLine } styleOptions={ styleOptions } userID={ chatUser.id } />,
      document.getElementById('webchat'), () => {
        directLine.postActivity({ type: "event", value: "", from: chatUser, name: "joined_conversation" })
          .subscribe(function (id) {
            console.log('"trigger requestWelcomeDialog" sent');
          });
        document.querySelector('#webchat > *').focus();
      });
  }

  GetUserInitials(user) {
    return 'You';
  }

  WebChatStyle(chatUser) {
    return {
      primaryFont: ['Karla', 'Arial', 'sans-serif'].join(', '),
      backgroundColor: 'transparent',
      sendBoxBackground: '#000',
      sendBoxButtonColorOnFocus: '#fff',
      sendBoxButtonColorOnHover: '#fff',
      sendBoxTextColor: '#fff',
      sendBoxHeight: 80,
      hideUploadButton: true,
      timestampColor: '#fff',
      botAvatarImage: botDisplayImage,
      botAvatarInitials: 'DH',
      userAvatarImage: `https://storage.googleapis.com/decoded-public/avatars/default.png`,
      userAvatarInitials: chatUser.userInitials,
      userAvatarBackgroundColor: 'white',
      avatarSize: 60,
    };

  }

  componentDidMount() {
    userApi.getPersonSummary()
        .then(value => {
          this.LoadWebChat(value.data);
        }).catch(reason => {
          console.log(reason)
    })
  }

  render() {
    return (
      <Grid
        container
        direction="column"
        justify="flex-end"
        alignItems="stretch"
        spacing={16}
        className="anchorToBottom"
      >
        <Grid item xs={12}>
          <div id="webchat" className="webChat" role="main"></div>
        </Grid>
        
      </Grid>
    );
  }
}

export default Conversation;
