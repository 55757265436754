import * as Msal from 'msal'

export default class AuthService {

    constructor () {

        this.applicationConfig = {
            clientID: process.env.REACT_APP_B2C_ClientId,
            authority: process.env.REACT_APP_B2C_Authority,
            b2cScopes: process.env.REACT_APP_B2C_Scopes.split(','),
        };

        let config = {
            auth: {
                clientId: this.applicationConfig.clientID,
                authority: this.applicationConfig.authority,
                validateAuthority: false,
                redirectUri: (errorDesc, token, error, userState) => { /* callback for login redirect */ },
                postLogoutRedirectUri: process.env.REACT_APP_Logout_RedirectUri,
            }
        }

        this.app = new Msal.UserAgentApplication(config);

        this.app.handleRedirectCallback(this.authCallback);
    }

    authCallback = (error, response) => {
        //handle redirect response
        console.log(response);
    }


    login = () => {

        var options = {
            scopes: this.applicationConfig.b2cScopes,
            redirectUri: process.env.REACT_APP_Logout_RedirectUri
        }

        return this.app.loginRedirect(options);
    }

    logout = () => {
        this.app.logout();
    }

    getUser = () => {
        const user = this.app.getAccount()
        if (user) {
            return user
        } else {
            return null
        }
    }

    loginInProgress = () => {

        return this.app.getLoginInProgress();
    }

    getToken = () => {

        var options = {
            scopes: this.applicationConfig.b2cScopes,
            redirectUri: process.env.REACT_APP_Logout_RedirectUri
        }

        return this.app.acquireTokenSilent(options).then(
            accessToken => {
                return accessToken
            },
            error => {
                return this.app
                    .acquireTokenRedirect(options)
                    .then(
                        accessToken => {
                            return accessToken
                        },
                        err => {
                            console.error(err)
                        }
                    )
            }
        )
    }
}


export const authService = new AuthService();
