import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import MoreVert from '@material-ui/icons/MoreVert';

import { authService } from '../../utils/auth.service'

class AppNav extends Component {

  state = {
    anchorEl: null,
    showBackButton: false
  };

  constructor (props) {
    super(props);
    this.showLogout = !!authService.getUser();

    this.props.history.listen((loc, action) => {
      this.updateNavOnLocation(loc);
    })
  }

  componentDidMount() {
    this.updateNavOnLocation(this.props.history.location);
  }

  updateNavOnLocation(loc) {
    if (loc.pathname === '/') {
      this.setState({
          showBackButton: false
      });
    }
    else {
      this.setState({
        showBackButton: true
    });
    }
  }

  logout = () => {
    authService.logout();
  };
  
  handleBackHome = () => {
    this.props.history.push('/');
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AppBar position="fixed">
        <Toolbar>
          { this.state.showBackButton ? 
            <IconButton
              onClick={this.handleBackHome}
            >
              <ArrowLeft/>
            </IconButton>
          :
            null
          }
          <div className="flexSpacer"></div>
          { this.showLogout ? 
            <div>
              <IconButton
                aria-owns={open ? 'menu-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleMenu}
                color="inherit"
              >
                <MoreVert />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.logout}>Logout</MenuItem>
              </Menu>
            </div>
          :
            null
          }
        </Toolbar>
      </AppBar>
    );
  }

}

export default withRouter(AppNav);