import React, { Component } from 'react';
import Grid from "@material-ui/core/Grid";
import { withStyles } from '@material-ui/core/styles';
import {BrowserRouter, Link, Switch} from "react-router-dom";
import {Button, Typography} from "@material-ui/core";
import UserContext from "../../utils/user.context";

const styles = theme => ({
});

class WelcomeBack extends Component {

    render() {

        const { context } = this.props;

        const { user } = context;

        return (
            <>
                <div className="containerCentered">
                    <Grid item xs={12}>
                        <Typography variant="h5" >Welcome Back, {this.props.user.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Link to="/conversation">
                            <Button>Start Conversation</Button>
                        </Link>
                    </Grid>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(props => (
    <UserContext.Consumer>
        {value => {
            return (<WelcomeBack context={value} classes={props.classes} {...props} />)
        }}
    </UserContext.Consumer>
));
