import React, {Component} from 'react';

import {withStyles} from '@material-ui/core/styles';


import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader, Checkbox,
    FormControl, FormControlLabel,
    FormHelperText,
    Grid, Input, InputAdornment,
    InputLabel, MenuItem, OutlinedInput, Select,
    TextField, Typography
} from "@material-ui/core";
import Container from "@material-ui/core/Container";
import {FormattedMessage} from "react-intl";
import {registrationApi} from "../../utils/registration.service";
import {Link} from "react-router-dom";

const styles = theme => ({
    root: {
        display: 'flex',
        width: "100%",
    },
    card: {
        width: "100%",
        maxWidth: "500px",
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    formControl: {
        minWidth: 120,
    },
    textField: {
        width: "100%",
    },
    checkbox: {
        textAlign: "left",
        width: "100%",
    },
    select: {
        textAlign: "left",
        width: "100%",
    },
});


class Register extends Component {

    constructor(props) {
        super(props);

        this.state = {
            error: undefined,
            registered: false,
            touched: {
                password: false,
                detail: {
                    name: {
                        family: false,
                        given: false,
                        preferred: false,
                    },
                    gender: false,
                    dob: false,
                    communicationConsent: false,
                    acceptTerms: false,
                    contactDetails: {
                        email: false,
                        postcode: false,
                        number: {
                            type: false,
                            country: false,
                            area: false,
                            prefix: false,
                            line: false,
                        }
                    },
                }
            },
            form: {
                password: undefined,
                detail: {
                    name: {
                        family: undefined,
                        given: undefined,
                        preferred: undefined,
                    },
                    gender: undefined,
                    dob: undefined,
                    communicationConsent: false,
                    acceptTerms: false,
                    contactDetails: {
                        email: undefined,
                        postcode: undefined,
                        number: {
                            type: undefined,
                            country: undefined,
                            area: undefined,
                            prefix: undefined,
                            line: undefined,
                        }
                    }
                },
            },
        };
    }

    handleFieldChange = (field, value) => {
        const newState = {...this.state};

        var fields = field.split('.').reverse();

        newState.submitError = null;
        this._setField(newState.form, newState.touched, fields.pop(), fields, value);

        this.setState(newState/*, this.validateForm*/);
    };

    _setField = (obj, touched, current, remainder, value) => {


        console.log(obj, current, remainder, value);
        if(remainder.length > 0) {

            this._setField(obj[current], touched[current], remainder.pop(), remainder, value);
        } else {

            obj[current] = value;
            touched[current] = true;
        }
    };

    register = () => {

        console.log(JSON.stringify(this.state.form));

        this.setState({loading: true, errorMessage: undefined});

        registrationApi.register(this.state.form)
            .then((data) => {

                console.log("Success", data);
                this.setState({loading: false, registered: true});
            }, (error) => {

                this.setState({loading: false, error: error});
                console.log("Error", error)
            });
    };

    render() {

        const {classes} = this.props;
        const {form, touched, loading, registered, error} = this.state;

        if(registered) {
            return (
                <Container>
                    <Card className={classes.card}>
                        <CardHeader title={<FormattedMessage id="registration.successful" defaultMessage={`Registered!`} />} />
                        <CardActions>
                            <Link to="/">
                            <Button variant="contained" color="primary" className={classes.button}>
                                <FormattedMessage id="home" defaultMessage={`Home`} />
                            </Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Container>
            );
        }

        let errorMessage = <></>;
        if(error) {

            errorMessage = <div>{error.message}</div>;
        }

        return (
            <Container>
                <Card className={classes.card}>
                    <CardHeader title={<FormattedMessage id="new.account" defaultMessage={`New Account`} />} />
                    <CardContent className={classes.cardContent}>

                        <div>
                            { errorMessage }
                        </div>

                        <form className={classes.form} autoComplete="off">

                            <Grid container spacing={2}>

                                <Grid item xs={12} sm={6}>

                                    <TextField
                                        className={classes.textField}
                                        label="First name"
                                        name="givenName"
                                        onChange={event =>
                                            this.handleFieldChange('detail.name.given', event.target.value)
                                        }
                                        value={form.detail.name.given}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        className={classes.textField}
                                        label="Surname"
                                        name="familyName"
                                        onChange={event =>
                                            this.handleFieldChange('detail.name.family', event.target.value)
                                        }
                                        value={form.detail.name.family}
                                        variant="outlined"
                                    />

                                </Grid>


                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        className={classes.textField}
                                        label="Preferred name"
                                        name="preferredName"
                                        onChange={event =>
                                            this.handleFieldChange('detail.name.preferred', event.target.value)
                                        }
                                        value={form.detail.name.preferred}
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <FormControl className={classes.select}>
                                        <InputLabel htmlFor="gender">Gender</InputLabel>
                                        <Select
                                            value={form.detail.gender}
                                            onChange={event =>
                                                this.handleFieldChange('detail.gender', event.target.value)
                                            }
                                        >
                                            <MenuItem value={"U"}>
                                                <em>None</em>
                                            </MenuItem>
                                            <MenuItem value={"M"}>Male</MenuItem>
                                            <MenuItem value={"F"}>Female</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        id="date"
                                        label="Birthday"
                                        type="date"
                                        defaultValue="2017-05-24"
                                        className={classes.textField}
                                        onChange={event =>
                                            this.handleFieldChange('detail.dob', event.target.value)
                                        }
                                        value={form.detail.dob}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                    {/*{showFirstNameError && (*/}
                                    {/*    <Typography*/}
                                    {/*        className={classes.fieldError}*/}
                                    {/*        variant="body2"*/}
                                    {/*    >*/}
                                    {/*        {errors.firstName[0]}*/}
                                    {/*    </Typography>*/}
                                    {/*)}*/}
                                </Grid>

                                <Grid item xs={4}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel htmlFor="country">Country</InputLabel>
                                        <Select
                                            value={form.detail.contactDetails.number.country}
                                            onChange={event =>
                                                this.handleFieldChange('detail.contactDetails.number.country', event.target.value)
                                            }
                                        >
                                            <MenuItem value="+1">
                                                United States
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>

                                <Grid item xs={8}>
                                    <TextField
                                        className={classes.textField}
                                        label="Mobile number"
                                        name="mobileNumber"
                                        onChange={event =>
                                            this.handleFieldChange('detail.contactDetails.number.line', event.target.value)
                                        }
                                        type={'tel'}
                                        value={form.detail.contactDetails.number.line}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        label="Zipcode"
                                        name="postcode"
                                        onChange={event =>
                                            this.handleFieldChange('detail.contactDetails.postcode', event.target.value)
                                        }
                                        value={form.detail.contactDetails.postcode}
                                        variant="outlined"
                                    />
                                </Grid>


                                <Grid item xs={12}>
                                    <TextField
                                        className={classes.textField}
                                        label="Email"
                                        name="email"
                                        onChange={event =>
                                            this.handleFieldChange('detail.contactDetails.email', event.target.value)
                                        }
                                        value={form.detail.contactDetails.email}
                                        type={'email'}
                                        variant="outlined"
                                    />
                                </Grid>

                                <Grid item xs={12}>

                                    <FormControl className={classes.textField} variant="outlined">
                                        <InputLabel htmlFor="adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="adornment-password"
                                            type={'password'}
                                            value={form.password}
                                            onChange={event =>
                                                this.handleFieldChange('password', event.target.value)
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel  className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={form.detail.communicationConsent}
                                                onChange={event =>
                                                    this.handleFieldChange('detail.communicationConsent', event.target.checked)
                                                }
                                                value="true"
                                                color="primary"
                                            />
                                        }
                                        label="Consent to receiving voice, messaging and email communication from Decoded Health."
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel className={classes.checkbox}
                                        control={
                                            <Checkbox
                                                checked={form.detail.acceptTerms}
                                                onChange={event =>
                                                    this.handleFieldChange('detail.acceptTerms', event.target.checked)
                                                }
                                                value="true"
                                                color="primary"
                                            />
                                        }
                                        label="Accept terms"
                                    />
                                </Grid>

                            </Grid>

                        </form>

                    </CardContent>
                    <CardActions>
                        <Button onClick={this.register} variant="contained" color="primary" className={classes.button}>
                            <FormattedMessage id="register" defaultMessage={`Register`} />
                        </Button>
                    </CardActions>
                </Card>
            </Container>
        );
    }
}


export default withStyles(styles)(Register);